import { api } from '../utils/api';
// 获取用户经验
export async function getExperienceLevelAll(data) {
    return api('/web/task/value/user', data, 'POST', true, true, false);
    // return api('/web/task/grade/user', data);
}

// 获取用户经验记录
export async function getExperienceLog(data) {
    return api('/web/task/value/logs', data, 'POST', true);
    // return api('/web/task/grade/user', data);
}